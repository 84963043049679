import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import PeopleIcon from "./../../assets/images/informativewall/people.gif"
import HandIcon from "./../../assets/images/informativewall/hand.gif"
import SecureIcon from "./../../assets/images/informativewall/secure.gif"
import TransferIcon from "./../../assets/images/informativewall/transfer.gif"
/* import { MdAdsClick } from "react-icons/md";
import DerivRechange from "../System/DerivRecharge"; */
import TournamentInvitation from "../System/TournamentInvitation";
import OurServices from "../System/OurServices";
const InformativeWall = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex items-center justify-center font-light md:text-2xl ">
       {/*  <a
          className="flex w-11/12 text-sm md:w-fit mx-auto md:my-10 justify-center items-center flex-col px-6 py-2 rounded-md bg-[#3a55f7] text-white font-medium hover:text-white cursor-pointer shadow-md"
          href="https://es.gowt.net/ib61380"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex text-center">
            ¡{t("fbsone")}
          </div>
          <div className="flex flex-wrap gap-4 text-center">{t("fbstwo")}! 
           <MdAdsClick  size={29} className="mx-auto"/>
          </div>
        </a> */}
      </div>

      <TournamentInvitation />
      <OurServices />
      <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 w-10/12 gap-3 mx-auto mt-6 mb-8">
        <div className="flex flex-col items-center justify-start gap-3 text-center bg-[#e6e6e6] rounded-3xl py-6 px-4">
          <img src={SecureIcon} alt="icono" className="w-24" />
          <div className="text-lg font-normal">
            <CountUp start={755309}  enableScrollSpy={true}  duration={20} end={785309}>
              {({ countUpRef, start }) => (
                <div>
                  {t('informativewall.more')} <span className="font-semibold" ref={countUpRef} />{' '}
                  <span className="font-semibold">{t('informativewall.transfersOne')}</span> {t('informativewall.transfersTwo')}
                </div>
              )}
            </CountUp>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start gap-3 text-center bg-[#e6e6e6] rounded-3xl py-6 px-4">
          <img src={PeopleIcon} alt="icono" className="w-24" />
          <div className="text-lg font-normal">
            <CountUp start={4500} enableScrollSpy={true} duration={20} end={5000}>
              {({ countUpRef, start }) => (
                <div>
                  {t('informativewall.more')} <span className="font-semibold" ref={countUpRef} /> <span className="font-semibold">{t('informativewall.customersOne')}</span>
                  {' '}{t('informativewall.customersTwo')}
                </div>
              )}
            </CountUp>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start gap-3 text-center bg-[#e6e6e6] rounded-3xl py-6 px-4">
          <img src={HandIcon} alt="icono" className="w-24" />
          <div className="text-lg font-normal">
            <div>{t('informativewall.more')} <span className="font-semibold">{t('informativewall.brokersOne')}</span>{' '}{t('informativewall.brokersTwo')}</div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start gap-3 text-center bg-[#e6e6e6] rounded-3xl py-6 px-4">
          <img src={TransferIcon} alt="icono" className="w-24" />
          <div className="text-lg font-normal">
            <div>{t('informativewall.more')} <span className="font-semibold">{new Date().getFullYear() - 2014} {t('informativewall.expOne')}</span> {t('informativewall.expTwo')}</div>
          </div>
        </div>
      </div>

     
      
    </div>
  );
};
export default InformativeWall;
