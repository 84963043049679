import { AiFillStar } from "react-icons/ai"
import { Link } from "react-router-dom"
import { visitWhatsapp } from "../Shared/Whatsapp"
import courses from "./../../data/courses.json"
import IconMod1 from "src/assets/images/courses/modulo1.jpeg"
import IconMod2 from "src/assets/images/courses/modulo2.jpeg"
import IconMod3 from "src/assets/images/courses/modulo3.jpeg"
import IconMod4 from "src/assets/images/courses/modulo4.jpeg"
import IconMod5 from "src/assets/images/courses/modulo5.jpeg"
import IconMod6 from "src/assets/images/courses/modulo6.jpeg"
import IconOfferCourse from "src/assets/images/services/coursetrading.png"
import { HiOutlinePresentationChartLine } from "react-icons/hi"
type TParams = {
  type: string
}
const AcademyList: React.FC<TParams> = ({ type }) => {
  const tomoney = (x: any) => {
    if (!x) x = ''
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  const getImage = (position:string) => {
    switch(position) {
      case 'Módulo 1':
      return IconMod1
      case 'Módulo 2':
      return IconMod2
      case 'Módulo 3':
      return IconMod3
      case 'Módulo 4':
      return IconMod4
      case 'Módulo 5':
      return IconMod5
      case 'Módulo 6':
      return IconMod6                              

    }
  }

  const calculateOffer = () => {
    const total = courses.map(c => c.priceUsd).reduce((a,b) => a+b) - (courses.map(c => c.priceUsd).reduce((a,b) => a+b) * 0.17)
    return total
  }
  return (
    <section className="w-11/12 mx-auto">
      <div className="text-center tracking-wide flex items-center gap-2 justify-center font-bold text-2xl ">
        Aprende Trading con nosotros
      </div>
      <div className="text-center w-24 mx-auto border-b-2 mt-6 border-green-admin-light"></div>
      <div className="leading-6 font-medium mt-6 text-center tracking-wide">
        Kforex academy nace de la iniciativa de mostrar mis resultados como Trader con  todos aquellos que viven el proceso del Trading
        Han pasado 5 años para lograr ver resultados en mi analisis y operativa, al darme cuenta que despues de tanto esfuerzo, lagrimas, sudor y noches sin dormir obtengo buenos Trades  decido empezar a enseñar a mis amigos cercanos y familiares , mi intenciom es acortar el camino y enseñarte de mis experiencias vividas
      </div>
      <div className="text-center w-full mx-auto border-b border-dashed mt-4 mb-4 border-gray-300"></div>
      <div className="mt-6">
        <a href={`${process.env.REACT_APP_FRONT}/KForex_Academia_Presentacion.pdf`} rel="noreferrer" target="_blank"
        className="text-black flex items-center justify-center gap-2 hover:text-green-admin-light font-normal text-base" >
          Presiona aquí para leer la presentación de nuestra academia
          <HiOutlinePresentationChartLine size={20} className="text-green-admin-light" />
        </a>
      </div>
      <div className="text-center w-full mx-auto border-b border-dashed mt-4 mb-4 border-gray-300"></div>
      <div className="grid grid-cols-1 xl:grid-cols-2 px-8 py-6 gap-2">
        <div className="grid-col-1 flex flex-col justify-between">
        <div className="text-2xl font-semibold">
            Compra ahora todos los cursos
          </div>
          <div className="text-3xl">
            y recibe un <span className="text-4xl">50%</span> de descuento
          </div>
          <div className="line-through font-medium mt-3">
            Antes: {/*courses.map(c => c.priceUsd).reduce((a,b) => a+b)*/}{' 1500'} USD
          </div>
          <div className="text-lg font-semibold flex items-center gap-2">
            Ahora: 
            <span className="px-1 py-1 rounded-sm text-black" style={{ background: "#f3b11f" }} >  ${calculateOffer()} USD  </span> ó {tomoney(calculateOffer() * 4000)} COP
          </div>
          <div  
            onClick={() => visitWhatsapp('Hola, Quisiera mas información a cerca del curso de trading de ISF.')} 
            className="w-fit bg-green-admin-light cursor-pointer text-white font-normal text-xl px-4 py-2 rounded-lg mt-4">
            Comprar ahora
          </div>
        </div>
        <div className="grid-col-1 flex flex-col items-center justify-center">
          <img 
          onClick={() => visitWhatsapp('Hola, Quisiera mas información a cerca del curso de trading de ISF.')} 
          src={IconOfferCourse} alt="course offer" 
          className="rounded-mg shadow-md cursor-pointer"/>
        </div>
      </div>
      
      <div className="text-center w-24 mx-auto border-b-2 mt-6 border-green-admin-light"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
        {courses?.map((course, index) => (
          <div className="max-w-sm mx-auto rounded overflow-hidden bg-white shadow-lg">
             <Link to={(type === 'home') ? `/academia/detalle/${index}` : `/aprender/detalle/${index}`} >
                <img className="w-full cursor-pointer" src={getImage(course.position)} alt="Imagen curso academia" />
             </Link>
            <div className="px-6 pt-4 pb-4">
              <div className="font-semibold text-base text-center h-14 flex items-center mb-2">{course.position} {course.name}</div>
              <p className="text-gray-700 text-xs text-justify" dangerouslySetInnerHTML={{__html: course.description.substring(0, 107).concat("...")}}>
              </p>
              <div className="text-center w-full mx-auto border-b border-dashed mt-4 mb-4 border-gray-300"></div>
              <Link to={(type === 'home') ? `/academia/detalle/${index}` : `/aprender/detalle/${index}`} >
              <div className="text-center text-green-admin-light text-xs w-full tracking-wide">
                ({course.opinions.length}) Opiniones
              </div>

              </Link>
              <div className="flex items-center gap-x-2 justify-center">
                {[1, 2, 3, 4, 5].map(_ => (
                  <AiFillStar size={18} style={{ color: "#f9d203" }} />
                ))}
              </div>

              <Link to={(type === 'home') ? `/academia/detalle/${index}` : `/aprender/detalle/${index}`} >
                <div className="mx-auto underline my-2 border border-white rounded-md w-fit px-2 cursor-pointer bg-white text-dark-green">
                  Más información
                </div>
              </Link>


              <div className="flex items-center justify-center gap-2 text-xs font-medium">

                <span className="px-2 py-1 font-bold rounded-sm text-gray-500 line-through">antes {course.priceBefore}</span>
              </div>

              <div className="flex items-center justify-center gap-2 text-sm font-medium">
                <span className="px-2 py-1 rounded-sm text-black" style={{ background: "#f3b11f" }}>{course.priceNow}</span>
                <span>ó</span>
                <span>{course.priceNowCop}</span>
              </div>

              <div className="text-center w-full mx-auto border-b border-dashed mt-4 mb-4 border-gray-300"></div>

              <div
                onClick={() => visitWhatsapp()}
                className="px-2 py-1 cursor-pointer rounded-sm text-white font-medium w-fit mx-auto bg-green-admin-light tracking-wide">
                Comprar aquí
              </div>
            </div>
            {/*<div className="px-6 pb-2">
              <div className="flex flex-wrap items-center gap-1 mt-3">
                {course.lesson.map(l => (
                  <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700">
                    {l.name}
                  </span>
                ))}
              </div>
            </div>*/}
          </div>
        ))}
      </div>
    </section>
  )
}
export default AcademyList 