import CardService from "../Shared/CardService";
import ImageVPN from "src/assets/images/services/vpnforex.png";
import ImageWeltrade from "src/assets/images/services/weltrade.jpg";
import ImageNequi from "src/assets/images/services/nequi.jpg";
import ImageDsf from "src/assets/images/services/dsfweb.jpg";
import ImageAcademy from "src/assets/images/services/coursetrading.png";
import ImageExchange from "src/assets/images/services/exchange.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
/* import { BiSupport } from "react-icons/bi";
import { SiTelegram } from "react-icons/si";
import { visitWhatsapp } from "../Shared/Whatsapp";
import whatsapp from "../../assets/images/whatsapp.png"; */

const OurServices: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
{/*       <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-4 py-2 w-11/12 mx-auto">
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center ">
            <BiSupport className="text-gray-600" size={30} />
          </div>
          <div className="font-normal text-xl mb-7">{t("telegramjoin")}</div>
          <a
            target="_blank"
            href="https://t.me/ISFcambiosadmin"
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center text-sky-500">
            <SiTelegram size={30} />
          </div>
          <div className="font-normal text-xl mb-7"> {t("forexjoin")} </div>
          <a
            target="_blank"
            href="https://t.me/KForexDeriv"
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center">
            <img src={whatsapp} className="w-8" alt="whatsapp"/>
          </div>
          <div className="font-normal text-xl mb-7 cursor-pointer">
            {t("comercialasesor")}
          </div>
          <span
            onClick={() => visitWhatsapp()}
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base cursor-pointer"
          >
            {t("chatwithasesor")}
          </span>
        </div>
      </div> */}
      <div className="text-center mt-10 text-lg md:text-3xl font-semibold">
        Nuestros servicios
      </div>
      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-y-4 gap-2 py-2">
        <CardService
          title={"Exchange saldos digitales"}
          image={ImageExchange}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ⭐️ Transacciones protegias con tecnología de cifrado avanzada.
              </li>
              <li>
                ⭐️ Variedad de opciones: tarjetas, cuentas, servicios en linea y
                más.
              </li>
              <li>⭐️ Mejores tasas de cambio de mercado.</li>
            </ul>
          }
        />

        <CardService
          title={"Academia de trading"}
          image={ImageAcademy}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ⭐️ Mejora tus habilidades de análisis técnico y fundamental.
              </li>
              <li>
                ⭐️ Aprende de expertos del trading con años de experiencia.
              </li>
              <li className="mb-4">⭐️ Accede a recursos exclusivos y actualizados.</li>

              <li>
              <Link
                  to="/academia"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"Crear cuenta indices sintenticos"}
          image={ImageWeltrade}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>⭐️ Aumenta tus ganancias en más de un 30%.</li>
              <li className="mb-2">
                ⭐️ Herramientas avanzadas para tomar decisiones, contenidos
                exclusivos y personalizados.
              </li>

              <li>
                <Link
                  to="https://es.gowt.net/ib61380"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"VPN Privada Forex oficial"}
          image={ImageVPN}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ⭐️ Protege tu identidad y datos personales de hackers y
                terceros.
              </li>
              <li>⭐️ Accede a contenido restringido en tu país o región.</li>
              <li className="mb-3">⭐️ Navega sin límites y sin rastreo.</li>

              <li>
              <Link
                  to='https://vpnforex.com/'
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"DERIV Recarga automática"}
          image={ImageNequi}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ⭐️ Ahora tus recargas a Deriv son instantáneas.
              </li>
              <li>
                ⭐️ Recarga automáticamente con nuestro bot telegram.
              </li>              
              <li className="mb-3">⭐️ Funciona 24/7 , sigue los pasos y paga desde app Nequi.</li>
              <li>
              <Link
                  to='https://t.me/tepagoya_bot'
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"Desarrolla tu software"}
          image={ImageDsf}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ⭐️ Soluciones tecnológicas que impulsan el crecimiento de tu negocio.
              </li>
              <li>⭐️ App Móviles con un equipo de expertos en desarrollo de sofware.</li>
              <li className="mb-3">⭐️ AppWeb y Backoffices en tiempo real.</li>

              <li>
              <Link
                  to='https://www.desarrollosinfronteras.com'
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />
      </div>
    </div>
  );
};

export default OurServices;
